<template>
  <LiefengContent>
    <template v-slot:title>社区频道统计</template>
    <template v-slot:contentArea>
      <!--文字部分-->
      <Card>
        <div class="title-div">
          <div class="title-left">
            <div class="item-num">
              <div class="num">{{numData.authentication}}</div>
              <div class="title">{{'实名认证人数'}}</div>
            </div>
            <div class="item-num">
              <div class="num">{{numData.introduce}}</div>
              <div class="title">{{'引入用户'}}</div>
            </div>
            <div class="item-num">
              <div class="num">{{numData.onlineHome}}</div>
              <div class="title">{{'网上家园人数'}}</div>
            </div>
          </div>
          <div class="title-right">
            <div class="item-num">
              <div class="num-weight">{{numData.userAmount}}</div>
              <div class="title">{{'社区用户'}}</div>
            </div>
          </div>
        </div>
      </Card>
      <!--用户画像-->
      <Card class="protarit-div">
        <div class="portrait-title">{{'用户画像'}}</div>
        <div class="pie-image">
          <!--性别分布-->
          <div class="title-padding">{{'性别分布'}}</div>
          <div class="pie-content">
            <div class="image">
              <VeRing :data="sexData"></VeRing>
            </div>
            <div class="table">
              <Table :columns="sexPeopleColumn" :data="sexPeopleData"></Table>
            </div>
          </div>
        </div>
        <!--各人群数量-->
        <div class="people-num">
          <div class="title-padding">{{'各人群数量'}}</div>
          <div>
            <VeHistogram :extend="hisExtend" :data="proPeoData"></VeHistogram>
          </div>
        </div>
      </Card>
      <!--社区用户统计-->
      <Card class="protarit-div">
        <div class="portrait-title">{{'社区用户统计'}}</div>
        <div class="link-div">
          <div class="select-div">
            <Select style="width:200px" v-model="dayModel" @on-change="changeUserDay">
              <Option v-for="item in dayList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
            <span
              style="line-height:30px;margin-left:10px"
              v-if="dayModel == 0"
            >{{sevenUserTime.startTime}}-{{sevenUserTime.endTime}}</span>
            <span
              style="line-height:30px;margin-left:10px"
              v-if="dayModel == 1"
            >{{monthUserTime.startTime}}-{{monthUserTime.endTime}}</span>
            <DatePicker
              v-if="dayModel == 2"
              v-model="selectdate"
              format="yyyy/MM/dd"
              type="daterange"
              placement="bottom-end"
              placeholder="选择时间区域"
              style="width: 250px;margin-left:10px"
              @on-change="userChangeDate"
            ></DatePicker>

            <Select
              style="width:100px;margin-left:auto"
              v-model="monthModel"
              @on-change="monthChange"
            >
              <Option
                v-for="item in monthList"
                :value="item.value"
                :key="item.value"
              >{{ item.label }}</Option>
            </Select>
          </div>
          <div class="select-line" style="text-align:center;font-weight:bold;font-size:16px">
            {{'社区用户'}}
            <VeLine :settings="chartSettings" :data="userStatData"></VeLine>
          </div>
        </div>
      </Card>
      <!--运营负责情况-->
      <Card class="protarit-div">
        <div class="portrait-title">{{'运营负责情况'}}</div>
        <!--表格信息-->
        <!-- <div class="protrait-table">
          <Table :columns="peopleColumn" :data="peopleData"></Table>
        </div>-->
        <!--机构各信息类别发布数统计-->
        <div class="link-div" style="height:0">
          <div class="select-div">
            <Select style="width:200px" v-model="dayModelOne" @on-change="changeDay">
              <Option v-for="item in dayList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
            <span
              style="line-height:30px;margin-left:10px"
              v-if="dayModelOne == 0 && dayModelOne != ''"
            >{{sevenTime.startTime}}-{{sevenTime.endTime}}</span>
            <span
              style="line-height:30px;margin-left:10px"
              v-if="dayModelOne == 1 && dayModelOne != ''"
            >{{monthTime.startTime}}-{{monthTime.endTime}}</span>
            <DatePicker
              v-if="dayModelOne == 2 && dayModelOne != ''"
              format="yyyy/MM/dd"
              type="daterange"
              placement="bottom-end"
              placeholder="选择时间区域"
              style="width: 250px;margin-left:10px"
              @on-change="changeOneDay"
            ></DatePicker>
          </div>
        </div>
        <div style="text-align:center;font-weight:bold;font-size:16px">{{'机构各信息类别发布数统计'}}</div>
        <div style="border-bottom:1px solid #ccc">
          <VeHistogram :extend="hisExtend" :data="institutionalDataOne"></VeHistogram>
        </div>

        <!--各机构当前栏目统计-->
        <div
          style="text-align:center;font-weight:bold;font-size:16px;padding:10px 0;"
        >{{'各机构当前栏目统计'}}</div>
        <div style="border-bottom:1px solid #ccc">
          <VeHistogram :extend="hisExtendData" :data="institutionalData"></VeHistogram>
        </div>

        <!--机构引入社区累计用户数对比-->
        <div
          style="text-align:center;font-weight:bold;font-size:16px;padding:10px 0;"
        >{{'机构引入社区累计用户数对比'}}</div>
        <div style="border-bottom:1px solid #ccc">
          <VeBar :extend="hisExtend" :data="StatIntroduceByData"></VeBar>
        </div>

        <!--饼状图-->
        <div class="link-div" style="height:20px;margin-bottom:10px">
          <div class="select-div">
            <Select style="width:200px" v-model="dayModelTwo" @on-change="changeDayTwo">
              <Option v-for="item in dayList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
            <span
              style="line-height:30px;margin-left:10px"
              v-if="dayModelTwo == 0 && dayModelTwo != ''"
            >{{sevenTimeTwo.startTime}}-{{sevenTime.endTime}}</span>
            <span
              style="line-height:30px;margin-left:10px"
              v-if="dayModelTwo == 1 && dayModelTwo != ''"
            >{{monthTimeTwo.startTime}}-{{monthTime.endTime}}</span>
            <DatePicker
              v-if="dayModelTwo == 2 && dayModelTwo != ''"
              format="yyyy/MM/dd"
              type="daterange"
              placement="bottom-end"
              placeholder="选择时间区域"
              style="width: 250px;margin-left:10px"
              @on-change="changeTwoDay"
            ></DatePicker>
          </div>
        </div>
        <div style="margin-top:40px;display:flex;width:100%">
          <div
            class="item-div"
            style="flex:1;text-align:center;font-weight:bold;font-size:16px"
            v-for="(item,index) in pieData"
            :key="index"
          >
            <span v-if="item.rows.length != 0">{{item.title}}</span>
            <VePie v-if="item.rows.length != 0" :data="item"></VePie>
            <span
              v-if="item.rows.length != 0"
              style="font-weight:bold;font-size:16px;"
            >合计:{{item.count}}</span>
          </div>

          <!-- <div class="item-div" style="flex:1;text-align:center;font-weight:blod;font-size:16px">
            {{'信息点赞数占比'}}
            <VePie :data="institutionalDatas"></VePie>
          </div>
          <div class="item-div" style="flex:1;text-align:center;font-weight:blod;font-size:16px">
            {{'信息分享数占比'}}
            <VePie :data="institutionalDatas"></VePie>
          </div>
          <div class="item-div" style="flex:1;text-align:center;font-weight:blod;font-size:16px">
            {{'信息参与人数占比'}}
            <VePie :data="institutionalDatas"></VePie>
          </div>-->
        </div>
      </Card>
      <!--信息更新频率-->
      <!-- <div class="content" style="margin-top:20px">
       
        <div class="table-div">
       
          <div class="table-left">
            <Card style="width:100%;height:691px;overflow:scroll">
              <div class="update-time">
                <div>&nbsp;</div>
                <div class="table-title">{{'用户访问排行版'}}</div>
                <div class="table-btn">{{'完整排行'}}</div>
              </div>
              <Table style="height:calc(100%-100px)" :columns="peopleColumn" :data="peopleData"></Table>
            </Card>
          </div>
          <div class="table-right">
            <Card style="width:100%;height:691px;overflow:scroll">
              <div class="update-time">
                <div>&nbsp;</div>
                <div class="table-title">{{'用户报名排行版'}}</div>
                <div class="table-btn">{{'完整排行'}}</div>
              </div>
              <Table style="height:calc(100%-100px)" :columns="peopleColumn" :data="peopleData"></Table>
            </Card>
          </div>
        </div>
      </div>-->
    </template>
  </LiefengContent>
</template>

<script>
//@route('/chartchannel')
// VeLine:折线图  VePie:饼图  VeHistogram:柱状图
import LiefengContent from "@/components/LiefengContent3";
import VeLine from "v-charts/lib/line.common";
import VePie from "v-charts/lib/pie.common";
import VeHistogram from "v-charts/lib/histogram.common";
import VeRing from "v-charts/lib/ring.common";
import VeBar from "v-charts/lib/bar.common";
export default {
  data() {
    return {
      // 机构各信息类别发布数统计
      selectdateOne: [],
      dayModelOne: "",
      institutionalDataOne: {
        columns: ["机构", "信息类", "信息发布类"],
        rows: []
      },

      // 机构各信息类别发布数统计结束
      // 饼图开始
      dayModelTwo: "",
      sevenTimeTwo: {
        startTime: "",
        endTime: ""
      },
      monthTimeTwo: {
        startTime: "",
        endTime: ""
      },
      pieData: [
        {
          title: "信息浏览数占比",
          count: 0,
          columns: ["机构", "数量"],
          rows: []
        },
        {
          count: 0,
          title: "信息点赞数占比",
          columns: ["机构", "数量"],
          rows: []
        },
        {
          count: 0,
          title: "信息分享数占比",
          columns: ["机构", "数量"],
          rows: []
        },
        {
          count: 0,
          title: "信息参与人数占比",
          columns: ["机构", "数量"],
          rows: []
        }
      ],
      // 饼图结束
      // 机构引入社区累计用户对比
      StatIntroduceByData: {
        columns: ["机构", "数量"],
        rows: []
      },

      // 机构引入社区累计用户对比结束
      // 社区用户开始
      userStatData: {
        columns: ["时间", "数量"],
        rows: []
      },
      changeUserDate: {},
      // 社区用户结束
      chartSettings: {
        area: true,
        itemStyle: {
          //面积图颜色设置
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(254,106,172,0.6)" // 0% 处的颜色
              },
              {
                offset: 1,
                color: "rgba(255,139,119,0.1)" // 100% 处的颜色
              }
            ],
            globalCoord: false // 缺省为 false
          }
        }
      },
      numData: {},
      dayModel: "3", //选择第几天
      dayList: [
        { label: "请选择", value: "3" },
        { label: "最近7天", value: "0" },
        { label: "最近30天", value: "1" },
        { label: "自定义", value: "2" }
      ],
      selectdate: [], //选择的时间段
      monthModel: "", //力度
      monthList: [
        { label: "日粒度", value: "1" },
        { label: "周粒度", value: "2" },
        { label: "月粒度", value: "3" }
      ],
      sevenTime: {
        startTime: "",
        endTime: ""
      },
      monthTime: {
        startTime: "",
        endTime: ""
      },
      sevenUserTime: {
        startTime: "",
        endTime: ""
      },
      monthUserTime: {
        startTime: "",
        endTime: ""
      },
      //机构用户折线图数据
      // 性别分布数据、
      sexData: {
        columns: ["性别", "人数"],
        rows: [
          { 性别: "男", 人数: "" },
          { 性别: "女", 人数: "" },
          { 性别: "未知", 人数: "" }
        ]
      },
      sexPeopleData: [],
      sexPeopleColumn: [
        { title: "性别", key: "sex", align: "center" },
        { title: "人数", key: "num", align: "center" },
        { title: "占比", key: "count", align: "center" }
      ],
      // 性别分布结束
      // 各人群数量
      proPeoData: {
        columns: ["类型", "总人数", "男", "女", "未知"],
        rows: []
      },
      // 各人群数量结束
      institutionalData: {
        columns: ["机构", "栏目数", "子栏目数"],
        rows: []
      },
      //机构用户折线图数据
      institutionalDatas: {
        columns: ["机构", "发布数"],
        rows: [
          { 内容分类: "信息类", 发布数: 1393 },
          { 内容分类: "图文类", 发布数: 3530 }
        ]
      },
      peopleColumn: [
        {
          title: "机构人员",
          key: "name",
          align: "center"
        },
        {
          title: "最近登录时间",
          key: "time",
          align: "center"
        },
        {
          title: "本月累计使用",
          key: "count",
          align: "center"
        }
      ], //右边表格的列数据
      peopleData: [
        {
          name: "白蘑菇",
          time: "2016-10-03",
          count: "2"
        },
        {
          name: "白蘑菇",
          time: "2016-10-03",
          count: "2"
        },
        {
          name: "白蘑菇",
          time: "2016-10-03",
          count: "2"
        }
      ],
      // 柱状图的样式
      hisExtend: {
        series: {
          barMaxWidth: 50
        }
      },
      hisExtendData: {
        series: {
          barMaxWidth: 50
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 50
          },
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 50
          }
        ]
      }
    };
  },
  methods: {
    //   方法部分
    //社区用户选择日期回调函数
    changeUserDay(value) {
      if (value == 0) {
        let data = {
          endDate: this.sevenUserTime.endTime,
          timeGranularity: this.monthModel,
          startDate: this.sevenUserTime.startTime
        };
        this.getStatUserByDate(data);
        return;
      } else if (value == 1) {
        let data = {
          startDate: this.monthUserTime.startTime,
          timeGranularity: this.monthModel,
          endDate: this.monthUserTime.endTime
        };
        this.getStatUserByDate(data);
        return;
      } else if (value == 3) {
        let data = {
          endDate: "",
          timeGranularity: this.monthModel,
          startDate: ""
        };
        this.getStatUserByDate(data);
        return;
      }
    },
    userChangeDate(value) {
      value[0] = this.$core.formatDate(new Date(value[0]), "yyyy-MM-dd");
      value[1] = this.$core.formatDate(new Date(value[1]), "yyyy-MM-dd");
      this.changeUserDate.startTime = value[0];
      this.changeUserDate.endTime = value[1];
      let data = {
        endDate: value[1],
        timeGranularity: this.monthModel,
        startDate: value[0]
      };
      this.getStatUserByDate(data);
      return;
    },
    // 选择了日/周的粒度
    monthChange() {
      let data = {
        endDate: "",
        startDate: "",
        timeGranularity: this.monthModel
      };
      if (this.dayModel == 0) {
        data.endDate = this.sevenUserTime.endTime;
        data.startDate = this.sevenUserTime.startTime;
      } else if (this.dayModel == 1) {
        data.endDate = this.monthUserTime.endTime;
        data.startDate = this.monthUserTime.startTime;
      } else if (this.dayModel == 2) {
        data.endDate = this.changeUserDate.startTime;
        data.startDate = this.changeUserDate.endTime;
      }

      this.getStatUserByDate(data);
      return;
    },
    // 饼图的
    changeDayTwo(value) {
      if (value == 0) {
        let data = {
          endDate: this.sevenTimeTwo.startTime,
          orgLevel: "",
          startDate: this.sevenTimeTwo.endTime,
          statType: 1
        };
        this.getPieList(data);
        return;
      } else if (value == 1) {
        let data = {
          endDate: this.monthTimeTwo.startTime,
          orgLevel: "",
          startDate: this.monthTimeTwo.endTime,
          statType: 1
        };
        this.getPieList(data);
        return;
      } else if (value == 3) {
        let data = {
          endDate: "",
          orgLevel: "",
          startDate: "",
          statType: 1
        };
        this.getPieList(data);
        return;
      }
    },
    changeTwoDay(value) {
      value[0] = this.$core.formatDate(new Date(value[0]), "yyyy-MM-dd");
      value[1] = this.$core.formatDate(new Date(value[1]), "yyyy-MM-dd");

      let data = {
        endDate: value[1],
        orgLevel: "",
        startDate: value[0],
        statType: 1
      };
      this.getPieList(data);
      return;
    },
    // 饼图结束
    // 机构各信息类别发布数统计选择了7天/半月选择框事件
    changeDay(value) {
      if (value == 0) {
        let data = {
          endDate: this.sevenTime.startTime,
          orgLevel: "",
          startDate: this.sevenTime.endTime,
          statType: 1
        };
        this.getStatColumnPublish(data);
        return;
      } else if (value == 1) {
        let data = {
          endDate: this.monthTime.startTime,
          orgLevel: "",
          startDate: this.monthTime.endTime,
          statType: 1
        };
        this.getStatColumnPublish(data);
        return;
      } else if (value == 3) {
        let data = {
          endDate: "",
          orgLevel: "",
          startDate: "",
          statType: 1
        };
        this.getStatColumnPublish(data);
        return;
      }
    },
    changeOneDay(value) {
      value[0] = this.$core.formatDate(new Date(value[0]), "yyyy-MM-dd");
      value[1] = this.$core.formatDate(new Date(value[1]), "yyyy-MM-dd");
      let data = {
        endDate: value[1],
        orgLevel: "",
        startDate: value[0],
        statType: 1
      };
      this.getStatColumnPublish(data);
      return;
    },

    // 获取当前时间并且转换为常见格式
    formatterData(value) {
      var date = new Date();
      var year = "";
      var month = "";
      var day = "";
      if (value == 1) {
        year = date.getFullYear();
        month = date.getMonth() + 1;
        day = date.getDate();
      } else if (value == 7 || value == 30) {
        let dates = new Date(date.getTime() - value * 24 * 3600 * 1000);
        year = dates.getFullYear();
        month = dates.getMonth() + 1;
        day = dates.getDate();
      }

      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let nowTime = year + "-" + month + "-" + day;
      return nowTime;
    },
    //接口部分
    // 获取机构各信息类别发布数统计
    getStatColumnPublish(data) {
      this.$get(
        "/statistic/api/symanage/pc/statColumn/queryStatColumnPublish",
        {
          endDate: data.endDate,
          oemCode: parent.vue.oemInfo.oemCode,
          orgCode: "",
          orgLevel: "",
          startDate: data.startDate,
          statType: data.statType
        }
      ).then(res => {
        if (res.code == 200) {
          let data = res.data;
          this.institutionalDataOne = {
            columns: ["机构", "信息类", "信息发布类"],
            rows: []
          };
          if (data.xaxis.length != 0) {
            data.xaxis.map(item => {
              data.yaxis.map(item2 => {
                if (item2.affiche && item2.information) {
                  this.institutionalDataOne.rows.push({
                    机构: item.orgName,
                    信息类: item2.affiche,
                    信息发布类: item2.information
                  });
                }
              });
            });
          }
        } else {
          this.$Message.error({
            content: res.desc,
            background: true
          });
          return;
        }
      });
    },
    // 获取饼图数据接口
    getPieList(data) {
      this.$get("/statistic/api/symanage/pc/statColumn/statColumnBehavior", {
        endDate: data.endDate,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: "",
        orgLevel: "",
        startDate: data.startDate,
        statType: data.statType
      }).then(res => {
        this.pieData = [
          {
            title: "信息浏览数占比",
            count: 0,
            columns: ["机构", "数量"],
            rows: []
          },
          {
            count: 0,
            title: "信息点赞数占比",
            columns: ["机构", "数量"],
            rows: []
          },
          {
            count: 0,
            title: "信息分享数占比",
            columns: ["机构", "数量"],
            rows: []
          },
          {
            count: 0,
            title: "信息参与人数占比",
            columns: ["机构", "数量"],
            rows: []
          }
        ];
        if (res.code != 200) {
          res.data.map(item => {
            if (item.browse) {
              if (item.browse.orgList.length != 0) {
                item.browse.orgList.map(item => {
                  this.pieData[0].rows.push({
                    机构: item.orgName,
                    数量: item.count
                  });
                });
                this.pieData[0].count = item.browse.total;
              }
            } else if (item.like) {
              if (item.like.orgList.length != 0) {
                item.like.orgList.map(item => {
                  this.pieData[1].rows.push({
                    机构: item.orgName,
                    数量: item.count
                  });
                });
                this.pieData[1].count = item.like.total;
              }
            } else if (item.share) {
              if (item.share.orgList.length != 0) {
                item.share.orgList.map(item => {
                  this.pieData[2].rows.push({
                    机构: item.orgName,
                    数量: item.count
                  });
                });
                this.pieData[2].count = item.share.total;
              }
            } else if (item.participate) {
              if (item.participate.orgList.length != 0) {
                item.participate.orgList.map(item => {
                  this.pieData[3].rows.push({
                    机构: item.orgName,
                    数量: item.count
                  });
                });
                this.pieData[3].count = item.participate.total;
              }
            }
          });
          for (var i = 0; i < this.pieData.length; i++) {
            if (this.pieData[i].rows.length == 0) {
              this.pieData.splice(i, 1); // 将使后面的元素依次前移，数组长度减1
              i--; // 如果不减，将漏掉一个元素
            }
          }
        } else {
          this.$Message.error({
            content: res.desc,
            background: true
          });
          return;
        }
      });
    },
    // 获取机构引入社区累计用户数对比
    getStatIntroduceByDate(data) {
      this.$get("/statistic/api/symanage/pc/userIncrease/statUserByDataScope", {
        endDate: data.endDate,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: "",
        orgLevel: "",
        startDate: data.startDate,
        statType: 1,
        timeGranularity: data.timeGranularity
      }).then(res => {
        this.StatIntroduceByData = {
          columns: ["机构", "数量"],
          rows: []
        };
        let data = res.data;
        if (data.xaxis.length != 0) {
          data.xaxis.map(item => {
            this.StatIntroduceByData.rows.push({ 机构: item.orgName });
          });
          data.yaxis.map((item, index) => {
            this.StatIntroduceByData.rows[index].数量 = item.userAmount;
          });
        }
        console.log(this.StatIntroduceByData);
      });
    },
    // 社区用户统计接口
    getStatUserByDate(data) {
      this.$get("/statistic/api/symanage/pc/userIncrease/statUserByDate", {
        endDate: data.endDate,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: "44",
        orgLevel: "2",
        startDate: data.startDate,
        statType: 2,
        timeGranularity: data.timeGranularity
      }).then(res => {
        this.userStatData = {
          columns: ["时间", "数量"],
          rows: []
        };
        let data = res.data;
        if (data.xaxis.length != 0) {
          data.xaxis.map(item => {
            this.userStatData.rows.push({ 时间: item.statDate });
          });
          data.yaxis.map((item, index) => {
            this.userStatData.rows[index].数量 = item.userAmount;
          });
        }
      });
    },
    //获取用户类别统计
    statUserByLabel() {
      this.$get("/statistic/api/symanage/pc/userCategory/statUserByLabel", {
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: "",
        orgLevel: "",
        statType: 1
      }).then(res => {
        let data = res.data;
        if (data.xaxis.length != 0) {
          data.xaxis.map(item => {
            this.proPeoData.rows.push({ 类型: item.labelName });
          });
          data.yaxis.map((item, index) => {
            this.proPeoData.rows[index].总人数 = item.userAmount;
            this.proPeoData.rows[index].男 = item.maleAmount;
            this.proPeoData.rows[index].女 = item.womanAmount;
            this.proPeoData.rows[index].未知 = item.unknownAmount;
          });
        }
      });
    },
    // 根据性别类别统计接口
    statUserBySex() {
      this.$get("/statistic/api/symanage/pc/userCategory/statUserBySex", {
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: "",
        orgLevel: "",
        statType: 1
      }).then(res => {
        let data = res.data;
        this.sexData.rows[0].人数 = data.maleAmount;
        this.sexData.rows[1].人数 = data.womanAmount;
        this.sexData.rows[2].人数 = data.unknownAmount;
        this.sexData.rows.map(item => {
          if (item.人数 == 0) {
            item.人数 = null;
          }
        });

        this.sexPeopleData = [];
        this.sexPeopleData[0] = {
          sex: "男",
          num: data.maleAmount,
          count: data.malePercent
        };
        this.sexPeopleData[1] = {
          sex: "女",
          num: data.womanAmount,
          count: data.womanPercent
        };
        this.sexPeopleData[2] = {
          sex: "未知",
          num: data.unknownAmount,
          count: data.unknownPercent
        };
      });
      console.log(this.sexPeopleData);
    },
    // 各机构当前栏目统计
    getQueryMenuColumn() {
      this.$get("/statistic/api/symanage/pc/statMenu/queryMenuColumn", {
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: "",
        orgLevel: "",
        statType: 1
      }).then(res => {
        let data = res.data;
        data.xaxis.map(item => {
          this.institutionalData.rows.push({ 机构: item.orgName });
        });
        data.yaxis.map((item, index) => {
          this.institutionalData.rows[index].栏目数 = item.menuTotal;
          this.institutionalData.rows[index].子栏目数 = item.childMenuTotal;
        });
      });
    },
    getHeadStat() {
      this.$get("/statistic/api/symanage/pc/statHead/communityHeadStat", {
        oemCode: parent.vue.oemInfo.oemCode
      }).then(res => {
        this.numData = res.data;
      });
    }
  },
  created() {
    this.sevenTime = {
      startTime: this.formatterData(7),
      endTime: this.formatterData(1)
    };
    this.monthTime = {
      startTime: this.formatterData(30),
      endTime: this.formatterData(1)
    };
    this.sevenTimeTwo = {
      startTime: this.formatterData(7),
      endTime: this.formatterData(1)
    };

    this.monthTimeTwo = {
      startTime: this.formatterData(30),
      endTime: this.formatterData(1)
    };
    this.sevenUserTime = {
      startTime: this.formatterData(7),
      endTime: this.formatterData(1)
    };
    this.monthUserTime = {
      startTime: this.formatterData(30),
      endTime: this.formatterData(1)
    };
    let data = {
      endDate: "",
      orgLevel: "",
      startDate: "",
      statType: 1
    };
    let params = {
      endDate: "",
      timeGranularity: ""
    };
    let userParams = {
      endDate: "",
      startDate: "",
      timeGranularity: ""
    };
    this.getStatColumnPublish(data);
    this.getPieList(data);
    this.getStatIntroduceByDate(params);
    this.getStatUserByDate(userParams);
    this.statUserByLabel();
    this.statUserBySex();
    this.getQueryMenuColumn();
    this.getHeadStat();
  },
  components: {
    LiefengContent,
    VeLine,
    VePie,
    VeHistogram,
    VeRing,
    VeBar
  }
};
</script>

<style lang="less" scoped>
.title-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  .title-left {
    width: 80%;
    display: flex;
    flex-flow: wrap;
    .item-num {
      width: 150px;
      height: 150px;
      margin-right: 10px;
      text-align: center;
      .num {
        padding-top: 50px;
        padding-bottom: 40px;
        font-size: 30px;
        font-weight: bold;
      }
      .title {
        font-size: 16px;
      }
    }
  }
  .title-center {
    width: 10%;
    .item-num {
      margin: 0 auto;
      width: 150px;
      height: 150px;
      margin-right: 10px;
      text-align: center;
      .num-red {
        font-size: 30px;
        padding-top: 50px;
        padding-bottom: 40px;
        font-weight: bold;
        color: red;
      }
      .title {
        font-size: 16px;
      }
    }
  }
  .title-right {
    width: 10%;
    .item-num {
      margin: 0 auto;
      width: 150px;
      height: 150px;
      margin-right: 10px;
      text-align: center;
      .num-weight {
        padding-top: 50px;
        padding-bottom: 40px;
        font-weight: bold;
        font-size: 40px;
      }
      .title {
        font-size: 16px;
      }
    }
  }
}
.protarit-div {
  width: 100%;
  margin-top: 20px;
  .portrait-title {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
    border-bottom: 1px solid #ccc;
    margin: 0 auto;
    padding-bottom: 10px;
  }
  .pie-image {
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    .title-padding {
      padding: 10px 0;
      font-weight: bold;
      font-size: 16px;
    }
    .pie-content {
      display: flex;
      justify-content: space-between;
      .image {
        width: 60%;
      }
      .table {
        width: 40%;
      }
    }
  }
  .people-num {
    .title-padding {
      font-weight: bold;
      font-size: 16px;
      padding: 10px 0;
    }
  }
  .link-div {
    width: 100%;
    height: 400px;
    padding: 10px 0;
    .select-div {
      display: flex;
      justify-content: flex-start;
    }
  }
  .protrait-table {
    padding: 10px 0;
  }
  // 饼图css
  .jus-div {
    width: 100%;
  }
}
.content {
  margin-top: 120px;
  .link-div {
    .select-div {
      display: flex;
      justify-content: flex-start;
    }
  }
}
.table-div {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .table-left,
  .table-right {
    width: 49.5%;
    min-height: 699px;
    overflow: scroll;
    .update-time {
      margin-bottom: 5px;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      text-align: center;
      .table-title {
        padding-left: 53px;
        font-weight: bold;
      }
      .table-btn {
        color: #169bd5;
        cursor: pointer;
        user-select: none;
      }
    }
  }
}
</style>